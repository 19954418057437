import styled from '@emotion/styled'

const SpacingComponent = styled.div`
  ${props => {
    let res = ``

    if (props.fontSize) {
      res += `font-size: ${props.fontSize} !important;`
    }

    if (props.margin) {
      res += `margin: ${props.margin} !important;`
    }
    
    if (props.padding) {
      res += `padding: ${props.padding} !important;`
    }

    return res
  }}
`

export default SpacingComponent
