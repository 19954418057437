import React from "react"
import styled from "@emotion/styled"
import mq from "../../utils/mq"
import RawLink from "../../utils/Link"

const Link = styled(RawLink)``

const Alert = styled.div`
  background-color: ${props => props.theme.colors.secondary};
  max-width: 479px;
  padding: 1rem;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: calc(${props => props.theme.typography.body} + 0.2vw);
  font-family: "Poppins", sans-serif;

  span.show-on-mobile {
    ${mq({
      display: ["inline", "none"],
    })}
  }

  span.hide-on-mobile {
    ${mq({
      display: ["none", "inline"],
    })}
  }

  ${Link} {
    cursor: pointer;
    padding: 1rem 4rem;
    box-shadow: 0px 0px 8px 3px #21212155;

    i {
      margin-right: 5px;
    }

    &:nth-of-type(1) {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-right: 0;
      // background-color: ${props => props.theme.colors.brand};
      background-color: ${props => props.theme.colors.secondary};
    }

    &:nth-of-type(2) {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background-color: ${props => props.theme.colors.secondary};
    }

    &:hover {
      background-color: #121212;
    }
  }
`

const ActionGroup = () => {
  return (
    <Alert>
      <div>Dear Customer,</div>
      Due to the ongoing Coronavirus we regret to inform you that the restuarant
      and takeaway are closed. We sincerely apologize, but hope to welcome you
      in the future.
      <div>Best, GURU</div>
    </Alert>
  )
  /*<ButtonGroup>
      <div>
        <Link to="https://book.dinnerbooking.com/dk/en-US/book/table/pax/630/2">
          <i className="fal fa-chair" />
          <span className="hide-on-mobile">Make a</span> Reservation
          <span className="show-on-mobile">s</span>
        </Link>
      </div>
      <Link to="/takeaway">
        <i className="fal fa-car-alt" />
        <span className="hide-on-mobile">Order</span> Takeaway
      </Link>
    </ButtonGroup>*/
}

export default ActionGroup
