import React from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ActionGroup from "../components/ui/ActionGroup"
import Button from "../components/ui/Button"

const Container = styled.div`
  display: flex;
  color: white;
  justify-content: space-around;
  padding-top: 10rem;
  text-align: center;
  background-color: ${props => props.theme.colors.background};
  height: calc(100vh - ${props => props.theme.navbar.height});
  h1 {
    margin-bottom: 0;
    font-weight: normal;
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <Container>
      <div>
        <h1>We apologize.</h1>
        <p>This page is not available.</p>
        <ActionGroup />
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
