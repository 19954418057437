import styled from '@emotion/styled'
import SpacingComponent from './SpacingComponent'

const Button = styled(SpacingComponent)`
  cursor: pointer;
  position: relative;
  padding: 1rem;
  border-radius: 2px;
  width: max-content;
  margin: 0.5rem 0;

  ${props => props.outline ? (`
    border: 1px solid white;
    &:hover {
      color: black;
      background: white;
    }
  `) : (`
    color: black;
    background-color: white;
    &:hover {
      background: #f1f5f1;
    }
  `)}
`

export default Button
